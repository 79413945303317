// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NadDPcAvzBCiJX5e4400jg\\=\\={padding:2em}.bukGHGKeobSZFzNGv24DyA\\=\\={color:green}.QHAwQGC4Tb2Z76Q\\+bD7oUg\\=\\={padding:1em;border-width:2px;border-style:solid;border-color:rgba(0,0,0,0)}.\\-3ZLQSNYInwaTVkX-Aj2uw\\=\\={color:#616161}.UcmE1oTjIe7V2oNp9cQ12g\\=\\={color:#616161;padding-top:1em}.mCi5lDd1Kyb5s8mPRRzptQ\\=\\={border-color:green;border-width:2px;border-style:solid}", "",{"version":3,"sources":["webpack://./src/js/components/RouteForm/RouteInfoDialog/RouteInfoDialog.module.scss","webpack://./src/css/_variables.module.scss"],"names":[],"mappings":"AAEA,4BACI,WAAA,CAGJ,4BACI,WCYY,CDThB,6BACI,WAAA,CACA,gBAAA,CACA,kBAAA,CACA,0BAAA,CAGJ,6BACI,aCbgB,CDgBpB,4BACI,aCjBgB,CDkBhB,eAAA,CAGJ,4BACI,kBCRY,CDSZ,gBAAA,CACA,kBAAA","sourcesContent":["@import \"../../../../css/variables.module\";\n\n.dialog {\n    padding: 2em;\n}\n\n.success-text {\n    color: $success_color;\n}\n\n.prices-container {\n    padding: 1em;\n    border-width: 2px;\n    border-style: solid;\n    border-color: transparent;\n}\n\n.price-info-text {\n    color: $subtle-text-color;\n}\n\n.extra-info {\n    color: $subtle-text-color;\n    padding-top: 1em;\n}\n\n.active-ticket {\n    border-color: $success_color;\n    border-width: 2px;\n    border-style: solid;\n}","$primary-color: #0057DF;\n$color-2: #E56B70;\n$color-3: #B0228C;\n$color-4: #EA3788;\n$color-5: #F391A0;\n$subtle-text-color: #616161;\n\n$first-section-margin: 1.5em;\n$basic-border-radius: 20px;\n$basic-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);\n\n//footer\n$footer-section-title-color: $primary-color;\n$footer-subsection-color: black;\n\n$rental-bg-color: #E9F9FF;\n\n$basic-font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n\n$success_color: green;\n$error_color: red;\n$link-color: #0AC2FF;\n$light-grey: #e7e7e7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "NadDPcAvzBCiJX5e4400jg==",
	"success-text": "bukGHGKeobSZFzNGv24DyA==",
	"prices-container": "QHAwQGC4Tb2Z76Q+bD7oUg==",
	"price-info-text": "-3ZLQSNYInwaTVkX-Aj2uw==",
	"extra-info": "UcmE1oTjIe7V2oNp9cQ12g==",
	"active-ticket": "mCi5lDd1Kyb5s8mPRRzptQ=="
};
export default ___CSS_LOADER_EXPORT___;
