// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../media/sesja/main_photo.webp";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YRQDeMyykyLSPcP-osbl4g\\=\\={background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;min-height:800px}.W88nshi7W4QdqjniKOjeuA\\=\\={background-color:#fff;padding:1rem 2rem 0 2rem;border-radius:.5rem}", "",{"version":3,"sources":["webpack://./src/js/components/Views/Main/MainView.module.scss"],"names":[],"mappings":"AAAA,4BACI,wDAAA,CACA,qBAAA,CACA,gBAAA,CAGJ,4BACI,qBAAA,CACA,wBAAA,CACA,mBAAA","sourcesContent":[".intro-photo-container {\n    background-image: url(\"../../../../media/sesja/main_photo.webp\");\n    background-size: cover;\n    min-height: 800px;\n}\n\n.route-form {\n    background-color: white;\n    padding: 1rem 2rem 0 2rem;\n    border-radius: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"intro-photo-container": "YRQDeMyykyLSPcP-osbl4g==",
	"route-form": "W88nshi7W4QdqjniKOjeuA=="
};
export default ___CSS_LOADER_EXPORT___;
