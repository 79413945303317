// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rdtVlm7bVJvih5-\\+fC7cBA\\=\\={display:flex;flex-direction:row;align-items:center;flex-grow:1;padding-left:3em}@media only screen and (max-width: 960px){.rdtVlm7bVJvih5-\\+fC7cBA\\=\\={padding-left:3em}}.O9PNt29pcFpVcrcEZvtjNA\\=\\={color:#0057df;flex-grow:1;display:flex;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/js/components/Header/MenuPC/MenuPC.module.scss","webpack://./src/css/_variables.module.scss"],"names":[],"mappings":"AAEA,6BACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,WAAA,CACA,gBAAA,CAGJ,0CACI,6BACI,gBAAA,CAAA,CAIR,4BACI,aCjBY,CDkBZ,WAAA,CACA,YAAA,CACA,wBAAA","sourcesContent":["@import \"../../../../css/variables.module\";\n\n.main-container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    flex-grow: 1;\n    padding-left: 3em;\n}\n\n@media only screen and (max-width: 960px) {\n    .main-container {\n        padding-left: 3em;\n    }\n}\n\n.button {\n    color: $primary-color;\n    flex-grow: 1;\n    display: flex;\n    justify-content: flex-end;\n}","$primary-color: #0057DF;\n$color-2: #E56B70;\n$color-3: #B0228C;\n$color-4: #EA3788;\n$color-5: #F391A0;\n$subtle-text-color: #616161;\n\n$first-section-margin: 1.5em;\n$basic-border-radius: 20px;\n$basic-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);\n\n//footer\n$footer-section-title-color: $primary-color;\n$footer-subsection-color: black;\n\n$rental-bg-color: #E9F9FF;\n\n$basic-font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n\n$success_color: green;\n$error_color: red;\n$link-color: #0AC2FF;\n$light-grey: #e7e7e7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "rdtVlm7bVJvih5-+fC7cBA==",
	"button": "O9PNt29pcFpVcrcEZvtjNA=="
};
export default ___CSS_LOADER_EXPORT___;
