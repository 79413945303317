import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PriceTable from "../../Prices/PriceTable/PriceTable";
import Request from '../../../utils/Request';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import styles from "./RoutesView.module.scss";
import { Link } from "react-router-dom";
export default function RoutesView() {
    const [basicPrices, setBasicPrices] = useState(undefined);
    //call it once
    useEffect(() => {
        loadPricesDefinition();
    }, []);
    function renderPricesGroups() {
        if (basicPrices === undefined) {
            return null;
        }
        const groups = [];
        let i = 0;
        for (const name in basicPrices) {
            groups.push(React.createElement(Accordion, { key: name, style: { marginTop: i === 0 ? "1em" : "0" } },
                React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreOutlinedIcon, null) },
                    React.createElement(Typography, null, name)),
                React.createElement(AccordionDetails, null,
                    React.createElement("div", { className: styles["price-group-container"] },
                        React.createElement(PriceTable, { basicPrices: basicPrices[name] }),
                        React.createElement("div", { className: styles["price-group-additional-info"] },
                            React.createElement(Typography, { variant: "body2" }, "*cena dotyczy biletu dwukierunkowego otwartego p\u0142atnego w formie przedp\u0142aty (przelew tradycyjny lub p\u0142atno\u015Bci online)"))))));
            ++i;
        }
        return groups;
    }
    function loadPricesDefinition() {
        new Request().get(`${BACKEND_SERVICE}/BasicPrices`).then((result) => {
            const parsedResult = JSON.parse(result).data;
            setBasicPrices(parsedResult);
        });
    }
    return (React.createElement("div", { style: { paddingTop: "12rem" } },
        React.createElement("div", { style: { paddingBottom: "5rem" } },
            React.createElement(Container, null,
                React.createElement(Typography, { variant: "h3" }, "Trasy"),
                React.createElement(Grid, { container: true, style: { marginTop: "2rem" }, spacing: 8 },
                    React.createElement(Grid, { item: true, xs: 12, md: 4 },
                        React.createElement("div", { style: { display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" } },
                            React.createElement("div", null,
                                React.createElement(Typography, { variant: "body1" }, "Nasza firma obs\u0142uguje wojew\u00F3dztwa opolskie, \u015Bl\u0105skie, dolno\u015Bl\u0105skie, a tak\u017Ce wybrane obszary wojew\u00F3dztw lubuskiego, \u0142\u00F3dzkiego oraz ma\u0142opolskiego.")),
                            React.createElement("div", null,
                                React.createElement(Typography, { variant: "h6" }, "Legenda"),
                                React.createElement(Typography, { variant: "body1", color: "inherit", style: { marginTop: "1em" } },
                                    React.createElement("span", { style: { color: "#D76889" } }, "Codzienne przejazdy (r\u00F3\u017Cowy)"),
                                    React.createElement("br", null),
                                    React.createElement("span", { style: { color: "#459EDE" } }, "Dodatkowe przejazdy (niebieski)\u200B"))))),
                    React.createElement(Grid, { item: true, xs: 12, md: 8 },
                        React.createElement("div", { id: "map", style: { width: "100%", overflow: "hidden" } },
                            React.createElement("iframe", { src: "https://www.google.com/maps/d/embed?mid=15ztCT_UnHFQbvZKqdAZ5VItVUpyXlM8&ehbc=2E312F", width: "100%", height: "500", style: { marginTop: "-60px" } })))))),
        React.createElement("div", { id: "prices", style: { paddingTop: "5rem", paddingBottom: "5rem", backgroundColor: "#F6F6F6" } },
            React.createElement(Container, null,
                React.createElement(Typography, { variant: "h3" }, "Cennik"),
                React.createElement(Grid, { container: true, style: { marginTop: "2rem" }, spacing: 8 },
                    React.createElement(Grid, { item: true, xs: 12, md: 4 },
                        React.createElement("div", { style: { display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" } },
                            React.createElement("div", null,
                                React.createElement(Typography, { variant: "body1" }, "Ceny w naszej firmie s\u0105 uzale\u017Cnione od miejscowo\u015Bci w Polsce. Przewozy do Niemiec zaczynaj\u0105 si\u0119 od 390 z\u0142, a do Holandii od 430 z\u0142.")),
                            React.createElement("div", null,
                                React.createElement(Typography, { variant: "h6" }, "Legenda"),
                                React.createElement(Typography, { variant: "body1", color: "inherit", style: { marginTop: "1em" } },
                                    React.createElement("span", { style: { color: "#D76889" } }, "Strefa 1 (r\u00F3\u017Cowy)"),
                                    React.createElement("br", null),
                                    React.createElement("span", { style: { color: "#459EDE" } }, "Strefa 2 (niebieski)\u200B"))))),
                    React.createElement(Grid, { item: true, xs: 12, md: 8 },
                        React.createElement("div", { id: "map", style: { width: "100%", overflow: "hidden" } },
                            React.createElement("iframe", { src: "https://www.google.com/maps/d/embed?mid=1KHozc8R_OuLOTmQSq75mv8hJbQZ4Dfo&ehbc=2E312F", width: "100%", height: "500", style: { marginTop: "-60px" } }))),
                    React.createElement(Grid, { item: true, xs: 12 }, renderPricesGroups()),
                    React.createElement(Grid, { item: true, xs: 12, style: { padding: "5 rem 0 5rem 0" } },
                        React.createElement("div", { style: { display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" } },
                            React.createElement(Link, { to: "/?sekcja=formularz", style: { textDecoration: "none" } },
                                React.createElement(Button, { color: "primary", variant: "contained" }, "SPRAWD\u0179 MOJ\u0104 CEN\u0118")))))))));
}
